<template>
  <ScrollContainer>
    <Container>
      <CardSection>
        <template #title> Support Options </template>

        <Card class="mb-3 assistance" :iconTop="true">
          <template #icon>
            <font-awesome-icon icon="users" />
          </template>
          <template #title>
            <span class="title">Relationship Managers' Direct Lines</span>
          </template>
          <p class="description">Contact our RMs directly to solve your issues faster.</p>
          <div class="flex flex-row flex-wrap justify-around mt-5">
            <ContactCard v-for="contact in contactInfo" :key="contact.name" :contact="contact" />
          </div>
        </Card>

        <Card class="mb-3 button" @click="goToBillingSupport">
          <template #icon>
            <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
          </template>
          <template #title>Billing Support</template>
          <p>Contact us if you have trouble with your billing or subscription.</p>
        </Card>

        <Card class="mb-3 button" @click="goToSupport">
          <template #icon>
            <font-awesome-icon :icon="['fas', 'life-ring']" />
          </template>
          <template #title>Technical Support</template>
          <p>
            If you suspect you have a bug, if you are having trouble loading part of the app, or if
            you are having other technical difficulties use this portal.
          </p>
        </Card>

        <Card class="mb-3 button" @click="goToUniversity">
          <template #icon>
            <font-awesome-icon icon="book-open" />
          </template>
          <template #title>Bolster University</template>
          <p>
            Bolster University houses a series of quick how-to videos to help you navigate our
            platform and answers some of the most common questions asked by our users, such as, how
            to setup invoicing and payments, upload your material list, or add items to your
            assembly.
          </p>
        </Card>
      </CardSection>

      <CardSection>
        <CardList>
          <CardListField class="center">
            <span> This Bolster build version </span>
            <strong>{{ $store.state.session.version }}</strong>
          </CardListField>
          <CardListField class="center">
            <span> Your company ID </span>
            <strong>{{ $store.state.session.company.company_id }}</strong>
          </CardListField>
          <CardListField class="center">
            <span> Your user ID </span>
            <strong>{{ $store.state.session.user.user_id }}</strong>
          </CardListField>
        </CardList>
      </CardSection>
    </Container>
  </ScrollContainer>
</template>

<script>
import ContactCard from '@/components/ui/Cards/ContactCard.vue'
import PageMixin from '../mixins/Page'
import JessicaPhoto from '../../assets/rm-photos/Jessica.png'
import KirbyPhoto from '../../assets/rm-photos/Kirby.png'
import AntonioPhoto from '../../assets/rm-photos/Antonio.png'
import AlexPhoto from '../../assets/rm-photos/Alex.png'

export default {
  mixins: [PageMixin],
  name: 'SupportPage',
  data() {
    return {
      contactInfo: [
        {
          name: 'Jessica Brietzke',
          title: 'Relationship Manager',
          phone: '+15878483219',
          email: 'jessica@bolsterbuilt.com',
          avatar: JessicaPhoto
        },
        {
          name: 'Kirby MacDonald',
          title: 'Relationship Manager',
          phone: '+15876038816',
          email: 'kirby@bolsterbuilt.com',
          avatar: KirbyPhoto
        },
        {
          name: 'Antonio Fernandez',
          title: 'Relationship Manager',
          phone: '+15875704711',
          email: 'antonio@bolsterbuilt.com',
          avatar: AntonioPhoto
        },
        {
          name: 'Alex Przibislawszky',
          title: 'Relationship Manager',
          phone: '+14253850048',
          email: 'alex@bolsterbuilt.com',
          avatar: AlexPhoto
        }
      ]
    }
  },
  methods: {
    async goToBillingSupport() {
      const link = await this.$store.dispatch('Keyvalue/get', {
        key: 'billing_link',
        global: true
      })
      c.openLink(link)
    },
    async goToSupport() {
      const link = await this.$store.dispatch('Keyvalue/get', {
        key: 'support_link',
        global: true
      })
      c.openLink(link)
    },
    async goToUniversity() {
      const udemy = await this.$store.dispatch('Keyvalue/get', {
        key: 'university_link',
        global: true
      })
      c.openLink(udemy)
    }
  },
  components: {
    ContactCard
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.avatar {
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: default;
  box-shadow:
    inset 0 0 0 16px rgba(0, 0, 0, 0.1),
    0 1px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
  background-size: cover;
  background-repeat: none;
  z-index: 1;
  display: inline-block;
  background-color: #1abc9c;

  &:before {
    content: '';
    display: table;
  }
  .avatar-initials {
    position: absolute;
    width: inherit;
    height: inherit;
    overflow: hidden;
    opacity: 1;
    transition: all 0.4s ease-in-out;
    transform: scale(1);
    h3 {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 75px;
      margin: 0 30px;
      padding: 45px 0 0 0;
      height: 140px;
      font-family: 'Open Sans', Arial, sans-serif;
      text-shadow:
        0 0 1px #fff,
        0 1px 2px rgba(0, 0, 0, 0.3);

      text-align: center;
      margin: auto auto;
      padding: 0;
      height: 200px;
      vertical-align: middle;
      padding-top: 60px;
    }
  }
  .avatar-info {
    position: absolute;
    background: rgba(22, 142, 118, 0.7);
    width: inherit;
    height: inherit;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(0);

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    h3 {
      color: #fff;
      text-transform: uppercase;
      white-space: pre-line;
      margin: 0px;
      padding-top: 40px;
      text-align: center;
      line-height: 0.9;
      letter-spacing: 0.1em;
      font-size: 10px;
    }

    p {
      color: #fff;
      padding: 10px 5px;
      font-style: italic;
      margin: 0px;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow:
      inset 0 0 0 1px rgba(255, 255, 255, 0.1),
      0 1px 2px rgba(0, 0, 0, 0.1);
    .avatar-initials {
      transform: scale(0);
      opacity: 0;
    }
    .avatar-info {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.assistance {
  padding: 8px 8px 0 8px;

  .title {
    font-size: 16px;
  }

  .description {
    font-size: 0.9rem !important;
    line-height: 1.5;
    font-weight: bold !important;
  }
}

@media (max-width: 576px) {
  .contact-card {
    align-items: center;
  }
}
</style>
